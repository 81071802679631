import { createApp } from 'vue';
import App from './App.vue';
import { createHead } from '@vueuse/head';
import router from './router';
import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import ru from './locales/ru.json';

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDJekYDtA80Nm3s6o6F3mLlqMIfPp2vnAg",
  authDomain: "wisher-pro.firebaseapp.com",
  projectId: "wisher-pro",
  storageBucket: "wisher-pro.appspot.com",
  messagingSenderId: "820847511853",
  appId: "1:820847511853:web:4ffeba53fcdcd2a48daba4",
  measurementId: "G-SSZR5FHLRR"
};

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

const messages = {
  en,
  ru
};

const browserLanguage = navigator.language.split('-')[0];
const locale = messages[browserLanguage] ? browserLanguage : 'en';

const i18n = createI18n({
  locale,
  fallbackLocale: 'en',
  messages
});

const app = createApp(App);
const head = createHead();
app.use(head);
app.use(router);
app.use(i18n);
app.mount('#app');

export { analytics, logEvent };