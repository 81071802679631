<template>
  <link rel="icon" href="/favicon.ico">
  <div class="main-container">
    <UserInfoHeader v-if="user" :user="user" class="user-info-header"/>
    <div class="wish-container" v-for="wish in wishes" :key="wish.id">
      <div class="wish-header">
        <h2 class="wish-title">{{ wish.title }}</h2>
        <img v-if="wish.images && wish.images.length > 0" :src="getWishImageUrl(wish.id, wish.images[0].id)" alt="Wish Image" class="wish-image">
      </div>
      <p v-if="wish.description" class="wish-description">{{ wish.description }}</p>
      <template v-if="wish.donation_comment">
        <p class="wish-donation-title">{{ $t('wishlist.howToGiveTitle') }}</p>
        <p class="wish-donation-comment">{{ wish.donation_comment }}</p>
      </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import UserInfoHeader from './UserInfoHeader.vue';

export default {
  name: 'UserInfo',
  components: {
    UserInfoHeader
  },
  data() {
    return {
      user: null,
      wishes: []
    };
  },
  mounted() {
    const shortId = this.$route.params.short_id;
    this.getUserInfo(shortId);
  },
  methods: {
    getUserInfo(shortId) {
      const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/rest/users/short_id/${shortId}`;
      axios.get(apiUrl)
        .then(response => {
          this.user = response.data;
          this.getWishes(this.user.id);
        })
        .catch(error => {
          console.error(error);
        });
    },
    getWishes(userId) {
      const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/rest/users/${userId}/wishes`;
      axios.get(apiUrl)
        .then(response => {
          this.wishes = response.data;
        })
        .catch(error => {
          console.error(error);
        });
    },
    getWishImageUrl(wishId, imageId) {
      return `${process.env.VUE_APP_API_BASE_URL}/rest/wishes/${wishId}/images/${imageId}`;
    }
  }
}
</script>

<style>

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  font-family: 'SF Pro', sans-serif;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  overflow: visible;
}

.user-info-header {
  margin-top: 60px;
}

.wish-container {
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin: 16px 0;
  width: calc(100% - 40px);
  max-width: 400px;
  text-align: left;
  position: relative;
}

@media (max-width: 767px) {
  .wish-container {
    width: calc(100% - 60px);
  }
}

@media (min-width: 768px) {
  .wish-container {
    width: 400px;
  }
}

.wish-container:last-child {
  margin-bottom: 100px;
}

.wish-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.wish-title {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 500;
  margin: 5px 0 0 5px;
  background-color: #EEF2F7;
  padding: 0px 0px;
  display: inline-block;
}

.wish-image {
  width: 75px;
  height: 75px;
  object-fit: cover;
  border-radius: 10px;
  margin-left: 16px;
  margin-top: -30px;
}

.wish-description {
  font-size: 15px;
  font-weight: 400;
  margin: 11px 5px 0 5px;
  line-height: 1.3;
}

.wish-donation-title {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin: 11px 5px 0 5px;
  color: #6D441C;
  background-color: #FEFCC6;
  padding: 0px 2px;
  display: inline-block;
}

.wish-donation-comment {
  font-size: 15px;
  font-weight: 400;
  margin: 11px 5px 5px 5px;
  line-height: 1.3;
}

</style>